<template>
  <div class="warranty-details">
    <!-- 申请延保 -->
    <!-- 头部区域-START -->
    <div class="warranty-details-header">
      <div class="details-header-left">MAC：{{ pageData.mac }}</div>
      <!-- <div class="details-header-right">
        <span @click="queryWarranty()">我的延保</span>
        <span @click="applyWarranty()">申请延保</span>
      </div> -->
    </div>
    <!-- 头部区域-END -->

    <!-- 内容区域-START -->
    <div class="warranty-details-main">
      <!-- 质保概况-START -->
      <div class="warranty-overview">
        <div class="warranty-overview-header warranty-title overview">
          <span class="title-overview-left">终端质保概况</span>
          <span class="title-overview-right">
            <span @click="queryWarranty()">我的延保</span>
            <!-- <span @click="applyWarranty()">申请延保</span> -->
          </span>
        </div>
        <div class="warranty-overview-main">
          <van-grid :border="false" :column-num="1">
            <van-grid-item>
              <div class="warranty-status">{{ pageData.warrantyStatus }}</div>
            </van-grid-item>
          </van-grid>
          <van-grid :column-num="3">
            <van-grid-item>
              <div class="warranty-overview-main-title total-black">
                发货日期
              </div>
              <div class="warranty-overview-main-num total-black">
                {{ pageData.sendTime }}
              </div>
            </van-grid-item>
            <van-grid-item>
              <div class="warranty-overview-main-title total-black">
                过换新日期
              </div>
              <div class="warranty-overview-main-num total-black">
                {{ pageData.warrantyTime }}
              </div>
            </van-grid-item>
            <van-grid-item>
              <div class="warranty-overview-main-title total-black">
                过保修日期
              </div>
              <div class="warranty-overview-main-num total-black">
                {{ pageData.expireTime }}
              </div>
            </van-grid-item>
          </van-grid>
        </div>
      </div>
      <!-- 质保概况-END -->
    </div>
    <!-- 内容区域-END -->

    <!-- 底部按钮-START -->
    <div class="warranty-details-footer">
      <div class="submit-button" @click="applyWarranty()">申请延保</div>
    </div>
    <!-- 底部按钮-END -->

    <!-- 申请延保理由弹窗-START -->
    <van-dialog
      use-slot
      title="申请延保"
      class="apply-reason"
      :show="pageData.showReason"
      show-cancel-button
      confirmButtonText="确定"
      @cancel="onClose()"
      @confirm="saveReason()"
    >
      <div class="apply-title">请输入延保天数：</div>
      <!-- <van-field
        v-model="pageData.date"
        label=""
        class="apply-date"
        :left-icon="dateImg"
        @focus="pageData.showDate = true"
        placeholder="请选择日期"
        autocomplete="off"
      /> -->
      <van-field
        v-model="pageData.date"
        label=""
        class="apply-date"
        required
        :formatter="formatter"
        placeholder="延保天数不能为空"
        autocomplete="off"
        :error="pageData.dateError"
      />
      <div class="apply-title">请输入延保原因：</div>
      <van-field
        v-model="pageData.reason"
        label=""
        required
        class="apply-reason-info"
        type="textarea"
        placeholder="延保原因不能为空且不能少于十个字"
        :error="pageData.reasonError"
        clearable
      />
    </van-dialog>
    <!-- 申请延保理由弹窗-END -->

    <!-- 日期选择-START -->
    <van-action-sheet
      v-model:show="pageData.showDate"
      :close-on-click-overlay="false"
    >
      <van-datetime-picker
        v-model="pageData.currentDate"
        type="date"
        title="选择年月日"
        :min-date="pageData.minDate"
        @cancel="pageData.showDate = false"
        @confirm="confirmDate(value)"
      />
    </van-action-sheet>
    <!-- 日期选择-END -->
  </div>
</template>

<script setup>
import { ref, reactive, defineProps, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
import { Dialog, Toast } from "vant";
import store from "@/store/index.js";
import { timestampToTime } from "@/utils/utils";
import {
  applyExtendWarranty,
  getTerminalWarrantyDetails,
} from "@/api/warranty";

// import dateImg from "@/assets/img/日期.png";

/* VUE插件注册-START */
const router = useRouter();
/* VUE插件注册-END */

/* 变量初始化-START */
const user_id = store.getters.userid;
const username = store.getters.name;
/* 遮罩层 */
const toast = ref(null);
/* 页面数据 */
const pageData = reactive({
  mac: router.currentRoute.value.query.mac
    ? router.currentRoute.value.query.mac
    : "" /* 终端mac */,
  device_type: "未知" /* 终端mac型号 */,
  showReason: false /* 申请延保理由弹窗 */,
  showDate: false /* 延保时间选框 */,
  date: "" /* 延保时间 */,
  reason: "" /* 延保理由 */,
  warrantyStatus: "未知" /* 终端质保状态 可换新、可保修、已过保 */,
  sendTime: "****-**-**" /* 发货日期 */,
  warrantyTime: "****-**-**" /* 过换新日期 */,
  expireTime: "****-**-**" /* 过保修日期 */,
  currentDate: new Date() /* 当前日期 */,
  minDate: new Date() /* 最小日期 */,
  dateError: false,
  reasonError: false,
});
/* 父页面传参 */
defineProps({
  dataList: {
    type: Array,
    default: () => [],
  },
});
/* 变量初始化-END */

/* 函数初始化-START */
/* 过滤输入的数字 */
const formatter = (value) => value.replace(/\D|^0/g, "");
/* 延保信息详情 */
const warrantyDetails = () => {
  if (!pageData.mac || !user_id) {
    return false;
  }
  getTerminalWarrantyDetails({ mac: pageData.mac }).then(
    (res) => {
      if (
        res.data &&
        res.data.code &&
        res.data.code === 200 &&
        res.data.data &&
        res.data.data.out_time
      ) {
        pageData.sendTime = res.data.data.out_time.split(" ")[0];
        pageData.warrantyTime = res.data.data.renew_time.split(" ")[0];
        pageData.expireTime = res.data.data.guarantee_time.split(" ")[0];
        res.data.data.device_type &&
          (pageData.device_type = res.data.data.device_type);
        let nowTime = timestampToTime(new Date(), 2);
        if (nowTime < pageData.warrantyTime) {
          pageData.warrantyStatus = "可换新";
        } else if (nowTime < pageData.expireTime) {
          pageData.warrantyStatus = "可保修";
        } else {
          pageData.warrantyStatus = "已过保";
        }
      }
    },
    (err) => {
      console.log(err);
    }
  );
};
/* 我的延保 */
const queryWarranty = () => {
  router.push({ path: "/warranty/list" });
};
/* 申请延保 */
const applyWarranty = () => {
  if (!pageData.mac) {
    Dialog({ message: "终端数据为空，暂不支持当前操作！" });
    return false;
  }
  if (!user_id) {
    Dialog({ message: "用户数据为空，暂不支持当前操作！" });
    return false;
  }
  /* 延保理由 */
  pageData.showReason = true;
};
/* 关闭弹窗 */
const onClose = () => {
  pageData.showReason = false;
  pageData.reason = "";
};
/* 提交延保申请 */
const saveReason = () => {
  const applyDate = pageData.date ? +pageData.date.trim() : 0;
  const applyReason = pageData.reason ? pageData.reason.trim() : "";
  pageData.dateError = false;
  pageData.reasonError = false;
  if (!applyDate) {
    pageData.dateError = true;
    return false;
  }
  if (!applyReason) {
    pageData.reasonError = true;
    return false;
  }
  if (applyReason.length < 10) {
    Dialog({ message: "申请延保理由不能低于10个字！" });
    return false;
  }
  toast.value = Toast.loading({
    duration: 0,
    forbidClick: true,
  });
  const applyServer = applyExtendWarranty({
    user_id: user_id,
    username: username,
    mac: pageData.mac,
    device_type: pageData.device_type,
    warranty_time: applyDate,
    warranty_reason: applyReason,
  });
  applyServer
    .then((result) => {
      toast.value.clear();
      if (result.data && result.data.code && result.data.code == 200) {
        Dialog({ message: "提交申请成功！" });
      } else {
        Dialog({ message: "提交申请失败！" + result.data.msg });
      }
      pageData.showReason = false;
    })
    .catch((err) => {
      toast.value.clear();
      Dialog({ message: "提交申请出错！" + err });
      pageData.showReason = false;
    });
};
/* 延保时间 */
const confirmDate = () => {
  if (!pageData.currentDate) {
    return false;
  }
  let time = timestampToTime(new Date(pageData.currentDate).getTime(), 2);
  pageData.date = time.split(" ")[0];
  pageData.showDate = false;
};
/* 函数初始化-END */

/* DOM渲染之前 */
onBeforeMount(() => {
  warrantyDetails();
});
</script>

<style lang="scss">
.warranty-details {
  height: 100%;
  .warranty-details-header {
    position: relative;
    width: 100%;
    height: 44px;
    box-sizing: border-box;
    border-style: none;
    border-color: unset;
    font-size: 14px;
    padding: 12px;
    font-weight: normal;
    font-style: normal;
    .details-header-left {
      height: 20px;
      font-weight: 700;
      font-size: 13px;
      color: rgb(16, 16, 16);
      font-style: normal;
      letter-spacing: 0px;
      line-height: 20px;
      text-decoration: none;
      word-break: keep-all;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .details-header-right {
      position: absolute;
      top: 0;
      right: 0;
      padding: 0 8px;
      font-weight: 400;
      font-size: 13px;
      color: rgb(50, 145, 248);
      font-style: normal;
      letter-spacing: 0px;
      line-height: 44px;
      text-decoration: underline;
      span:first-child {
        margin-right: 8px;
      }
    }
  }
  .warranty-details-main {
    margin: 0 12px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 1);
    .warranty-overview {
      .warranty-title {
        position: relative;
        height: 48px;
        padding: 13px 0px 13px 40px;
        box-sizing: border-box;
        text-decoration: none;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 22px;
        border-bottom: 1px solid #eeeeee;
        .title-overview-right {
          position: absolute;
          right: 0;
          padding: 0 18px;
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #1677ff;
          line-height: 20px;
        }
      }
      .warranty-status {
        font-family: PingFangSC-Semibold;
        overflow-wrap: break-word;
        font-weight: 400;
        font-size: 37px;
        color: rgba(22, 119, 255, 1);
        letter-spacing: 0px;
        line-height: 150px;
        text-decoration: none;
        white-space: nowrap;
      }
      .warranty-overview-main-title.total-black,
      .warranty-overview-main-num.total-black {
        font-family: PingFangSC-Regular;
        font-size: 13px;
        color: rgba(102, 102, 102, 1);
        font-style: normal;
        letter-spacing: 0px;
        line-height: 18px;
        text-decoration: none;
      }
      .warranty-overview-main-title.total-black {
        font-size: 13px;
        line-height: 18px;
      }
      .warranty-overview-main-num.total-black {
        font-size: 16px;
        line-height: 25px;
        color: rgba(51, 51, 51, 1);
      }
    }
  }
  .apply-reason {
    border-radius: 8px;
    .van-dialog__header {
      height: 44px;
      line-height: 44px;
      box-sizing: border-box;
      padding: 0 12px;
      text-align: left;
      border-bottom: 1px solid #eeeeee;
    }
    .van-dialog__content {
      padding: 9px 12px;
      .apply-title {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
        margin: 5px 0;
      }
      .apply-date {
        height: 30px;
        box-sizing: border-box;
        border-radius: 4px;
        border: 1px solid #999999;
        padding: 4px;
        .van-field__control {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 20px;
        }
      }
      .van-icon__image {
        position: relative;
        top: 2px;
      }
      .apply-reason-info {
        height: 75px;
        border-radius: 4px;
        border: 1px solid #999999;
        padding: 6px;
      }
    }
    .van-dialog__footer {
      .van-dialog__cancel {
        color: #333333;
      }
      .van-dialog__confirm {
        color: #333333;
      }
    }
  }
  .warranty-details-footer {
    position: fixed;
    width: 100%;
    bottom: 50px;
    .submit-button {
      color: #1677ff;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 49px;
      text-align: center;
    }
  }
}
</style>
